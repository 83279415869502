<template>
  <div class="forms-table">
      <div v-show="forms && deviceList" class="trips-list-container">
        <b-table
            striped hover :items="forms" :fields="fields" :selectable="selectable"
            select-mode="single"
            responsive="sm" class="trips-list-table"
            :tbody-tr-class="getRowClass"
            ref="formsTable" @row-selected="selectRow" @row-clicked="rowClicked"
        >

          <template v-slot:cell(template)="row">
            <div class="flex-row justify-content-center">
              <FormTemplateAvatar :template="row.item.template"></FormTemplateAvatar>
            </div>
          </template>

          <template v-slot:cell(owner)="row">
            <div class="flex-column align-items-center">
              <UserAvatar :user="row.item.owner"></UserAvatar>
            </div>
          </template>

          <template v-slot:cell(related_id)="row">
            <div class="flex-column align-items-center">
              <div v-if="row.item.related_type !== 'device'">N/A</div>
              <DeviceAvatar v-else-if="deviceList && row.item.device_id && getDevice(row.item.device_id)"
                            :device="getDevice(row.item.device_id)"></DeviceAvatar>
              <div v-else>Device not Available</div>
            </div>
          </template>

          <template v-slot:cell(state)="row">
            <div class="flex-column align-items-center">
              <i :class="getFormStateIcon(row.item.state)"></i>
              {{formatFormState(row.item.state)}}
            </div>
          </template>
        </b-table>
      </div>
      <div v-if="(forms === null || forms.length === 0)" class="trips-list-container">
        <div class="no-trips-container">
          <h3 class="no-trips-text">No Forms found for this period.</h3>
        </div>
      </div>
  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as config from '@/config'
import moment from 'moment/moment'
import { DateTimeHelper as dt } from '@/components/helpers/DateTimeHelper'
import DeviceAvatar from '@/components/device/DeviceAvatar.vue'
import UserAvatar from '@/components/users/UserAvatar.vue'
import FormTemplateAvatar from '@/components/forms/FormTemplateAvatar.vue'

export default {
  name: 'forms-table',
  components: {FormTemplateAvatar, UserAvatar, DeviceAvatar},
  props: {
    forms: Array,
    editable: Boolean,
    selectable: Boolean,
    show_device_names: Boolean
  },
  emits: [
  ],
  data: function () {
    return {
      selectedDevice: null,
      isAdmin: null,
      // Filters
      editedRows: [],
      initialValues: {},
      trip_ids: [],
      filter: null,
      filterOn: [],
      onFiltered: [],
      sortDirection: 'asc',
      deviceList: null,
      formStates: {
        new: 'New',
        review_required: 'Review Required',
        complete: 'Complete'
      },
      rowStateHighlights: {
        review_required: 'warning'
      }
    }
  },
  async mounted() {
    this.isAdmin = await this.$auth.roleIn(config.roles.admin)
    await this.getDevices()
  },
  methods: {
    async getDevices() {
      let deviceCall = await DataProvider.getDeviceList(
        ['device_id', 'device_name', 'device_imei', 'device_code', 'icon', 'device_type', 'features'])
      this.deviceList = deviceCall.data
    },
    getDevice(deviceId) {
      let device = Object.values(this.deviceList).find(d => d.device_id === deviceId)
      return device || null
    },
    selectRow (rows) {
      let devices = rows.map(row => this.getDevice(row.device_id))
      this.$emit('row-selected', rows, devices)
    },
    rowClicked(row) {
      this.$emit('row-clicked', row, this.getDevice(row.related_id))
    },
    formatFormState (state) {
      if (Object.hasOwn(this.formStates, state)) {
        return this.formStates[state]
      } else {
        return state
      }
    },
    getFormStateIcon(state) {
      if (Object.hasOwn(this.$config.icons.form_states, state)) {
        return this.$config.icons.form_states[state]
      } else {
        return this.$config.icons.form_states.unknown
      }
    },
    getRowClass(item, type) {
      console.log(item, type)
      if (Object.hasOwn(this.rowStateHighlights, item.state)) {
        return 'table-striped table-body-centered ' + this.rowStateHighlights[item.state]
      } else {
        return 'table-striped table-body-centered'
      }
    }
  },
  computed: {
    fields() {
      let fields = [
        {key: 'template', label: 'Template', sortable: true},
        {key: 'owner', label: 'Owner', sortable: true},
        {key: 'related_id', label: 'Device', sortable: true, skip_csv: true},
        {key: 'created', label: 'Create Time', sortable: true, formatter: dt.timestampToLocalDateTime},
        {key: 'updated', label: 'Updated Time', sortable: true, formatter: dt.timestampToLocalDateTime},
        {key: 'state', label: 'State', sortable: true, formatter: this.formatFormState},
      ]
      return fields
    }
  },
  watch: {
    trips (newTrips) {
      this.cacheUneditedTrips(newTrips)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.is-unsaved {
  background-color: $theme-color-primary-3;
}

.fixed-width-save {
  display: flex;
  flex-flow: row nowrap;
  width: 3em;
  justify-content: center;
}

.trips-list-container {
  //width: 99%;
  //min-width: 600px;
  padding: 5px;
  overflow-y: auto;
  max-height: 100%;
}

.align-content-center {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.align-content-center input {
  margin-bottom: 0;
}

.save-icons {
  font-size: 1em!important;
}

.no-trips-text {
  color: $text-color-invert
}

.template-icon {
  width: 38px;
  font-size: 36px;
  text-align: center;
}

</style>
