var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forms-table" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.forms && _vm.deviceList,
            expression: "forms && deviceList"
          }
        ],
        staticClass: "trips-list-container"
      },
      [
        _c("b-table", {
          ref: "formsTable",
          staticClass: "trips-list-table",
          attrs: {
            striped: "",
            hover: "",
            items: _vm.forms,
            fields: _vm.fields,
            selectable: _vm.selectable,
            "select-mode": "single",
            responsive: "sm",
            "tbody-tr-class": _vm.getRowClass
          },
          on: { "row-selected": _vm.selectRow, "row-clicked": _vm.rowClicked },
          scopedSlots: _vm._u([
            {
              key: "cell(template)",
              fn: function(row) {
                return [
                  _c(
                    "div",
                    { staticClass: "flex-row justify-content-center" },
                    [
                      _c("FormTemplateAvatar", {
                        attrs: { template: row.item.template }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "cell(owner)",
              fn: function(row) {
                return [
                  _c(
                    "div",
                    { staticClass: "flex-column align-items-center" },
                    [_c("UserAvatar", { attrs: { user: row.item.owner } })],
                    1
                  )
                ]
              }
            },
            {
              key: "cell(related_id)",
              fn: function(row) {
                return [
                  _c(
                    "div",
                    { staticClass: "flex-column align-items-center" },
                    [
                      row.item.related_type !== "device"
                        ? _c("div", [_vm._v("N/A")])
                        : _vm.deviceList &&
                          row.item.device_id &&
                          _vm.getDevice(row.item.device_id)
                        ? _c("DeviceAvatar", {
                            attrs: { device: _vm.getDevice(row.item.device_id) }
                          })
                        : _c("div", [_vm._v("Device not Available")])
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "cell(state)",
              fn: function(row) {
                return [
                  _c("div", { staticClass: "flex-column align-items-center" }, [
                    _c("i", { class: _vm.getFormStateIcon(row.item.state) }),
                    _vm._v(
                      " " + _vm._s(_vm.formatFormState(row.item.state)) + " "
                    )
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _vm.forms === null || _vm.forms.length === 0
      ? _c("div", { staticClass: "trips-list-container" }, [_vm._m(0)])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-trips-container" }, [
      _c("h3", { staticClass: "no-trips-text" }, [
        _vm._v("No Forms found for this period.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }