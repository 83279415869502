<template>
  <div class="forms-container content">
    <div class="forms-header">
      <div>
        <i class="icon-button"
        :class="$config.icons.general.back"
        v-b-tooltip
        title="Back"
        @click="$router.back()"></i>
      </div>
      <div>
        <h1>Protekt Forms</h1>
      </div>
      <div></div>
    </div>
    <div class="forms-controls-container">
      <div class="forms-control-inner">
        <div class="forms-control">
          <label>Devices:</label>
            <DeviceSelector v-model="selectedDevices" emit_on_load dropdown_mode return_prop="device_id"
                            @input="deviceChanged" selection_mode="multi"></DeviceSelector>
        </div>
        <div class="forms-control">
          <label>Form Type:</label>
          <b-dropdown :text="formTypesText">
            <b-dropdown-header>Form Types</b-dropdown-header>
            <b-dropdown-form>
              <b-form-checkbox :checked="allTypesSelected" @change="toggleSelectAllFormTypes">Any</b-form-checkbox>
              <b-form-checkbox-group :options="formTypeOptions" v-model="formTypes" @input="refreshForms">

              </b-form-checkbox-group>
            </b-dropdown-form>
          </b-dropdown>
<!--          <b-select :options="formTypeOptions" v-model="formType" @change="refreshForms"></b-select>-->
        </div>
        <div class="forms-control">
          <label>Date Range:</label>
           <DateRangeSelector horizontal select_picker
                              default_filter_mode="Any"
                              @input="dateSelected"
                              @date_mode_change="dateModeChange"
                              @select_month="monthSelectChanged"
                              @select_custom_start="selectCustomStart"
                              @select_custom_end="selectCustomEnd"
                              @select_calendar_year="selectCalYear"
                              @select_financial_year="selectFiYear"
           ></DateRangeSelector>
        </div>
        <div class="forms-control">
          <label>Form Template:</label>
            <b-select :options="templateFilterOptions" v-model="templateFilter" @change="refreshForms"></b-select>
        </div>
        <div class="forms-control">
          <label>State:</label>
          <b-select :options="stateFilterOptions" v-model="stateFilter" @change="refreshForms"></b-select>
        </div>

        <div class="forms-control-export slim-controls" >
          <button @click="createNew">
            <i class="fa" :class="$config.icons.nav.forms" />
            New
          </button>
          <button @click="refreshForms">
            <i class="fa" :class="$config.icons.general.refresh" />
            Refresh
          </button>

<!--          <i class="fa" :class="$config.icons.general.export"></i>-->
<!--          <button @click="$bvModal.show('logbook-export-modal')"><i class="fa" :class="$config.icons.general.export"></i> Export Logbook</button>-->
        </div>
      </div>
      </div>
    <div class="trip-container-outer">
      <LoadingBox :loading="loading"></LoadingBox>
      <div v-show="forms" class="trips-table-container">
        <FormsTable :forms="forms" :total_rows="totalRows" show_device_names @row-clicked="selectRow"></FormsTable>
      </div>
    </div>
    <div class="pagination-container">
      <div class="total-trip-indicator">
        Results: {{totalRows}}
      </div>
      <div class="page-controls">
        <b-form-select
          v-model="perPage"
          @change="onPageSizeChange"
          id="perPageSelect"
          size="sm"
          :options="pageOptions"
          class="page-select"
        >
        </b-form-select>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @change="onPageChange"
          align="fill"
          size="sm"
          class="my-0 flex-grow-1"
        ></b-pagination>
      </div>
    </div>
    <b-modal id="edit-form-modal" centered class="modal-content" size="lg" ok-only ok-title="Close"
             :title="isNew ? 'Create Form': 'Edit Form'">
      <b-tabs>
        <b-tab title="Form">
          <FormEdit :form="selectedForm" :device="selectedDevice" @changed="selectedFormChange" :create_new="isNew"
                    modal_id="edit-form-modal" :related_types="['device', null]"></FormEdit>
        </b-tab>
        <b-tab title="History" v-if="!isNew">
          <h2>Audit Log</h2>
          <audit-log v-if="selectedForm" related_type="form" :related_id="selectedForm.id"></audit-log>
        </b-tab>
      </b-tabs>

    </b-modal>

  </div>
</template>

<script>

import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as config from '@/config'
import DateRangeSelector from '@/components/shared/DateRangeSelector.vue'
import LoadingBox from '@/components/helpers/LoadingBox.vue'
import DeviceSelector from '@/components/shared/DeviceSelector.vue'
import FormsTable from '@/components/forms/FormsTable.vue'
import FormEdit from '@/components/forms/FormEdit.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'

export default {
  name: 'forms',
  components: {AuditLog, FormEdit, FormsTable, DeviceSelector, LoadingBox, DateRangeSelector},
  data: function () {
    return {
      loading: true,
      devices: null,
      selectedDevices: 'all',
      forms: [],
      isAdmin: null,
      formTypeOptions: [
        {
          text: 'None',
          value: null
        },
        {
          text: 'Devices',
          value: 'device'
        },
        {
          text: 'Trips',
          value: 'trip'
        }
      ],
      formTypes: ['trip', 'device', null],
      filterMode: 'Today',
      filterOptions: [
        'Today',
        'Yesterday',
        'This Week',
        'Custom'
      ],
      stateFilterOptions: [
        {
          text: 'Any',
          value: null
        },
        {
          text: 'New',
          value: 'new'
        },
        {
          text: 'Review Required',
          value: 'review_required'
        },
        {
          text: 'Complete',
          value: 'complete'
        }
      ],
      stateFilter: null,
      templateFilter: null,
      templates: null,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
      ],
      editedRows: [],
      initialValues: {},
      customDate: null,
      trip_ids: [],
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      onFiltered: [],
      totalRows: 0,
      sortDirection: 'asc',
      startDate: null,
      endDate: null,
      // Values to synchronise with the log book exporter
      dateFilterMode: null,
      defaultMonth: null,
      defaultStartDate: null,
      defaultEndDate: null,
      dateRange: null,
      defaultFinancialYear: null,
      defaultCalendarYear: null,
      selectedForm: null,
      selectedDevice: null,
      isNew: false,
      devicesList: []
    }
  },
  async mounted() {
    this.isAdmin = await this.$auth.roleIn(config.roles.admin)
    this.templates = await this.getTemplates()
    this.devices = await this.getDevicelist()
    // await this.updateDevices()
    // await this.refreshTrips()
    if (Object.hasOwn(this.$route.query, 'form_id')) {
      this.selectedForm = await this.getForm(this.$route.query.form_id)
      this.selectedDevice = await this.getDevice(this.selectedForm.related_id)
      this.$bvModal.show('edit-form-modal')
    }
  },
  computed: {
    templateFilterOptions () {
      let opts = [{
        value: null,
        text: 'Any'
      }]
      if (this.templates) {
        opts = opts.concat(this.templates.map((t) => { return { value: t.template_id, text: t.name } }))
      }
      return opts
    },
    allTypesSelected() {
      return this.formTypeOptions.every(v => this.formTypes.includes(v.value))
    },
    formTypesText () {
      if (!this.allTypesSelected) {
        return this.formTypes.map(key => this.formTypeOptions.find(i => i.value === key).text).toString()
      } else {
        return 'Any'
      }
    }
  },
  methods: {
    async getTemplates() {
      let resp = await DataProvider.getTemplates()
      if (resp.success) {
        return resp.data.templates
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    dateModeChange (newMode) {
      this.dateFilterMode = newMode
    },
    monthSelectChanged (newMonth) {
      this.defaultMonth = newMonth
    },
    selectCustomStart (newStart) {
      this.defaultStartDate = newStart
    },
    selectCustomEnd (newEnd) {
      this.defaultEndDate = newEnd
    },
    selectCalYear (newYear) {
      this.defaultCalendarYear = newYear
    },
    selectFiYear (newYear) {
      this.defaultFinancialYear = newYear
    },
    dateSelected (dates) {
      this.startDate = dates.start
      this.endDate = dates.end
      if (this.selectedDevices !== 'all') {
        this.refreshForms()
      }
    },
    changeTypeFilter() {
      console.log(this.formTypes)
    },
    async refreshForms () {
      this.loading = true
      console.log(this.formTypeOptions.every(v => this.formTypes.includes(v.value)))
      let formtypes = this.formTypeOptions.every(v => this.formTypes.includes(v.value)) ? [] : this.formTypes // Leave this unset if nothing is filtered.
      console.log('', this.formTypes, formtypes)
      let resp = await DataProvider.getForms(this.perPage, (this.currentPage - 1) * this.perPage,
        this.stateFilter, this.startDate, this.endDate, this.selectedDevices, formtypes, this.templateFilter)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
        this.forms = []
        this.totalRows = 0
        this.loading = false
        return []
      } else {
        this.totalRows = resp.data.total_forms
        this.forms = resp.data.forms
        this.loading = false
      }
    },
    async getForm(formId) {
      let resp = await DataProvider.getForm(formId)
      if (resp.success) {
        console.log(resp.data)
        return resp.data.form
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async getDevicelist() {
      let deviceCall = await DataProvider.getDeviceList(
        ['device_id', 'device_name', 'device_imei', 'device_code', 'icon', 'device_type', 'features'])
      this.deviceList = deviceCall.data
    },
    getDevice(deviceId) {
      console.log(this.deviceList)
      return Object.values(this.deviceList).find(d => d.device_id === deviceId)
    },
    deviceChanged() {
      this.refreshForms()
    },
    /***
     * Pagination Controls select a new page.
     * @param newPage
     */
    onPageChange (newPage) {
      this.currentPage = newPage
      this.refreshForms()
    },
    /****
     * Pagination controls change page size
     * @param newPageSize
     */
    onPageSizeChange (newPageSize) {
      this.perPage = newPageSize
      this.refreshForms()
    },
    selectRow(row, device) {
      this.selectedForm = row
      this.selectedDevice = device
      this.isNew = false
      this.$bvModal.show('edit-form-modal')
    },
    selectedFormChange() {
      // if (this.isNew) {
      //   this.$bvModal.hide('edit-form-modal')
      // }
      this.refreshForms()
    },
    createNew () {
      this.isNew = true
      this.selectedForm = null
      this.$bvModal.show('edit-form-modal')
    },
    toggleSelectAllFormTypes() {
      if (this.allTypesSelected) {
        this.formTypes = []
      } else {
        this.formTypes = this.formTypeOptions.map(option => option.value)
      }
      this.refreshForms()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.forms-container {
  display: flex;
  flex-direction: column;
  background-color: $theme-color-background-1;
  overflow: hidden;
  width: 80%;
  min-width: 600px;
}

.forms-header {
  display: flex;
  flex-direction: row;
  font-size: 2em;
  justify-content: space-between;
  align-items: center;
  color: $text-color-heading;
  font-weight: 600;
  padding: 5px;
  background-color: $theme-color-background-2;
}

.forms-controls-container {
  z-index: 5;
  display: flex;
  flex-direction: row;
  background-color: $theme-color-background-1;
  color: white;
  border-bottom: 1px solid darkgray;
  justify-content: stretch;
}

.forms-control-inner {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 5px;
  justify-content: flex-start;
  border: 1px solid $theme-color-primary-3;
  border-radius: 10px;
  flex-grow: 1;

}

.forms-control-inner> div:not(:first-child) {
  margin-left: 2em
}

.forms-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forms-control-export{
  display: flex;
  align-content: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-grow: 1;
}

.trip-container-outer {
  position: relative;
  min-height: 300px;
  background: $theme-color-background-2;
  overflow: hidden;
}

.trips-table-container{
  //width: 99%;
  //min-width: 600px;
  padding: 5px;
  overflow: auto;
  max-height: 100%;
}

.pagination-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
  justify-content: space-between;
  border-top: 1px solid darkgray;
}

.page-controls {
  display: flex;
  flex-flow: row nowrap;
}

.page-select {
  width: 10%;
  min-width: 75px;
}

.total-trip-indicator {
  color: white;
  font-weight: 500;
}

</style>
