var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forms-container content" },
    [
      _c("div", { staticClass: "forms-header" }, [
        _c("div", [
          _c("i", {
            directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
            staticClass: "icon-button",
            class: _vm.$config.icons.general.back,
            attrs: { title: "Back" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ]),
        _vm._m(0),
        _c("div")
      ]),
      _c("div", { staticClass: "forms-controls-container" }, [
        _c("div", { staticClass: "forms-control-inner" }, [
          _c(
            "div",
            { staticClass: "forms-control" },
            [
              _c("label", [_vm._v("Devices:")]),
              _c("DeviceSelector", {
                attrs: {
                  emit_on_load: "",
                  dropdown_mode: "",
                  return_prop: "device_id",
                  selection_mode: "multi"
                },
                on: { input: _vm.deviceChanged },
                model: {
                  value: _vm.selectedDevices,
                  callback: function($$v) {
                    _vm.selectedDevices = $$v
                  },
                  expression: "selectedDevices"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "forms-control" },
            [
              _c("label", [_vm._v("Form Type:")]),
              _c(
                "b-dropdown",
                { attrs: { text: _vm.formTypesText } },
                [
                  _c("b-dropdown-header", [_vm._v("Form Types")]),
                  _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { checked: _vm.allTypesSelected },
                          on: { change: _vm.toggleSelectAllFormTypes }
                        },
                        [_vm._v("Any")]
                      ),
                      _c("b-form-checkbox-group", {
                        attrs: { options: _vm.formTypeOptions },
                        on: { input: _vm.refreshForms },
                        model: {
                          value: _vm.formTypes,
                          callback: function($$v) {
                            _vm.formTypes = $$v
                          },
                          expression: "formTypes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "forms-control" },
            [
              _c("label", [_vm._v("Date Range:")]),
              _c("DateRangeSelector", {
                attrs: {
                  horizontal: "",
                  select_picker: "",
                  default_filter_mode: "Any"
                },
                on: {
                  input: _vm.dateSelected,
                  date_mode_change: _vm.dateModeChange,
                  select_month: _vm.monthSelectChanged,
                  select_custom_start: _vm.selectCustomStart,
                  select_custom_end: _vm.selectCustomEnd,
                  select_calendar_year: _vm.selectCalYear,
                  select_financial_year: _vm.selectFiYear
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "forms-control" },
            [
              _c("label", [_vm._v("Form Template:")]),
              _c("b-select", {
                attrs: { options: _vm.templateFilterOptions },
                on: { change: _vm.refreshForms },
                model: {
                  value: _vm.templateFilter,
                  callback: function($$v) {
                    _vm.templateFilter = $$v
                  },
                  expression: "templateFilter"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "forms-control" },
            [
              _c("label", [_vm._v("State:")]),
              _c("b-select", {
                attrs: { options: _vm.stateFilterOptions },
                on: { change: _vm.refreshForms },
                model: {
                  value: _vm.stateFilter,
                  callback: function($$v) {
                    _vm.stateFilter = $$v
                  },
                  expression: "stateFilter"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "forms-control-export slim-controls" }, [
            _c("button", { on: { click: _vm.createNew } }, [
              _c("i", {
                staticClass: "fa",
                class: _vm.$config.icons.nav.forms
              }),
              _vm._v(" New ")
            ]),
            _c("button", { on: { click: _vm.refreshForms } }, [
              _c("i", {
                staticClass: "fa",
                class: _vm.$config.icons.general.refresh
              }),
              _vm._v(" Refresh ")
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "trip-container-outer" },
        [
          _c("LoadingBox", { attrs: { loading: _vm.loading } }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.forms,
                  expression: "forms"
                }
              ],
              staticClass: "trips-table-container"
            },
            [
              _c("FormsTable", {
                attrs: {
                  forms: _vm.forms,
                  total_rows: _vm.totalRows,
                  show_device_names: ""
                },
                on: { "row-clicked": _vm.selectRow }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "pagination-container" }, [
        _c("div", { staticClass: "total-trip-indicator" }, [
          _vm._v(" Results: " + _vm._s(_vm.totalRows) + " ")
        ]),
        _c(
          "div",
          { staticClass: "page-controls" },
          [
            _c("b-form-select", {
              staticClass: "page-select",
              attrs: {
                id: "perPageSelect",
                size: "sm",
                options: _vm.pageOptions
              },
              on: { change: _vm.onPageSizeChange },
              model: {
                value: _vm.perPage,
                callback: function($$v) {
                  _vm.perPage = $$v
                },
                expression: "perPage"
              }
            }),
            _c("b-pagination", {
              staticClass: "my-0 flex-grow-1",
              attrs: {
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                align: "fill",
                size: "sm"
              },
              on: { change: _vm.onPageChange },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "edit-form-modal",
            centered: "",
            size: "lg",
            "ok-only": "",
            "ok-title": "Close",
            title: _vm.isNew ? "Create Form" : "Edit Form"
          }
        },
        [
          _c(
            "b-tabs",
            [
              _c(
                "b-tab",
                { attrs: { title: "Form" } },
                [
                  _c("FormEdit", {
                    attrs: {
                      form: _vm.selectedForm,
                      device: _vm.selectedDevice,
                      create_new: _vm.isNew,
                      modal_id: "edit-form-modal",
                      related_types: ["device", null]
                    },
                    on: { changed: _vm.selectedFormChange }
                  })
                ],
                1
              ),
              !_vm.isNew
                ? _c(
                    "b-tab",
                    { attrs: { title: "History" } },
                    [
                      _c("h2", [_vm._v("Audit Log")]),
                      _vm.selectedForm
                        ? _c("audit-log", {
                            attrs: {
                              related_type: "form",
                              related_id: _vm.selectedForm.id
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", [_vm._v("Protekt Forms")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }